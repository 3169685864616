import { Chart } from "react-google-charts";

export interface PieChartProps {
  data: (string | number)[][];
  options: any;
}



export const GooglePieChart = (chartProps: PieChartProps) => {
  return (
    <Chart
      chartType="PieChart"
      data={chartProps.data}
      options={chartProps.options}
      width={"100px"}
      height={"100px"}
    />
  )
}
export default GooglePieChart;