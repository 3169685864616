import { API_URLS } from '../constants/appConstants'
import API from './api'
import { ChangePaymentStatus } from '../types/properties'

const getNonPaymentFinancialData = async () => {
  try {
    const response = await API.get(API_URLS.GET_NON_PAYMENT_OVERVIEW_DATA)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getLandlordMetricsOverviewData = async (property?: number) => {
  try {
    const URL = property
      ? `${API_URLS.GET_LANDLORD_METRICS_OVERVIEW_DATA}?property_id=${property}`
      : API_URLS.GET_LANDLORD_METRICS_OVERVIEW_DATA
    const response = await API.get(URL)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getTenantMetricsOverviewData = async () => {
  try {
    const URL = API_URLS.GET_TENANT_METRICS_OVERVIEW_DATA
    const response = await API.get(URL)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const gePaymentHistoryList = async () => {
  try {
    const response = await API.get(API_URLS.GET_PAYMENt_HISTORY_LIST)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getLandlordPaymentHistoryList = async (propertyId?: number) => {
  const url = propertyId
    ? `${API_URLS.LANDLORD_OVERVIEW_LIST}?property_id=${propertyId}`
    : API_URLS.LANDLORD_OVERVIEW_LIST
  try {
    const response = await API.get(url)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const getTenantPaymentHistory = async () => {
  try {
    const response = await API.get(API_URLS.GET_TENANT_PAYMENT_HISTORY)
    if (response.status === 201 || response.status === 200) {
      return response.data
    } else {
      throw `request failed with status ${response.status}`
    }
  } catch (err) {
    return err
  }
}

const updatePaymentStatus = async (data: ChangePaymentStatus) => {
  return new Promise((resolve, reject) => {
    API.put(API_URLS.UPDATE_PAYMENT_STATUS, data)
      .then((response) => {
        if (response.status === 201 || response.status === 200) {
          if (response.data.success) {
            return resolve(response.data)
          } else {
            return reject(response.data)
          }
        } else {
          throw `request failed with status ${response.status}`
        }
      })
      .catch((err) => {
        return reject(err)
      })
  })
}

export const overviewService = {
  getNonPaymentFinancialData,
  getLandlordMetricsOverviewData,
  getTenantMetricsOverviewData,
  gePaymentHistoryList,
  updatePaymentStatus,
  getLandlordPaymentHistoryList,
  getTenantPaymentHistory,
}
