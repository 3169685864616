import { Card, CardBody } from 'reactstrap';
import GooglePieChart from '../GooglePieChart/GooglePieChart';

export interface CardProps {
  chartData: any;
  chartOptions: any;
  children: any;
  className?: string;
}

export const OverviewDetailChartCard = (props: CardProps) => {

  return (
    <Card className={`h-100 ${props.className}`}>
      <CardBody className='d-flex align-items-center gap-3'>
        <div className="chart">
          <GooglePieChart data={props.chartData} options={props.chartOptions} />
        </div>
        <div className="content w-100">
          {props.children}
        </div>
      </CardBody>
    </Card>
  )
}