import { useEffect, useState } from "react";
import moment from "moment";

import { Input } from "reactstrap";
import { Badge } from "primereact/badge";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { classNames } from "primereact/utils";
import { Paginator, PaginatorPageChangeEvent } from "primereact/paginator";
import { setActivePageHeader } from "../../store/auth/authSlice";
import { useAppDispatch } from "../../hooks/storeHook";
import { TenantPaymentList } from "../../types/tenantDetails";


export interface TenantPaymentListProps {
    paymentListData: TenantPaymentList[]
    totalCount: number
}
const TenantPaymentListData = (props: TenantPaymentListProps) => {
    const dispatch = useAppDispatch();
    const { paymentListData, totalCount } = props

    const [first, setFirst] = useState<number[]>([0, 0, 0]);
    const [rows, setRows] = useState([10, 10, 10]);

    const onPageChange = (e: PaginatorPageChangeEvent, index: number) => {
        setFirst(first.map((f, i) => (index === i ? e.first : f)));
        setRows(rows.map((r, i) => (index === i ? e.rows : r)));
    };

    useEffect(() => {
        dispatch(setActivePageHeader("Overview"));
    }, []);


    const formatRent = (rowData: TenantPaymentList) => {
        return `USD ${rowData.payment_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
    };

    const dateFormat = (date: string) => {
        return moment(date).format("MMMM D, YYYY")
    }

    const StatusTemplate = (rowData: TenantPaymentList) => {
        return (
            <Badge
                className={classNames({
                    'status-badge badge-list': rowData.status === 'p',
                    'status-badge badge-success': rowData?.status === 'u',
                })}

                severity={rowData.status === 'p' ? 'success' : rowData.status === 'u' ? 'danger' : null}
                value={rowData.status_display}
            />
        )
    }

    return (
        <>
            <div className="data-list datatable-listing pb-2 mb-3">
                <DataTable scrollable editMode="row" value={paymentListData} dataKey="id" tableStyle={{ minWidth: '50rem' }}>
                    <Column sortable className="fw-semibold text-black" field="payment_amount" header="Payment amount" body={formatRent}></Column>
                    <Column sortable className="fw-semibold text-black" field="payment_date" header="Payment date" body={dateFormat}></Column>
                    <Column sortable className="fw-semibold text-black" field="status" header="Status" body={StatusTemplate}></Column>
                </DataTable>
                <div className="pagination-section mt-3 px-4 d-flex align-items-center">
                    <div className="pagination-container ms-auto">
                        <Paginator template={''} first={first[2]} rows={rows[2]} totalRecords={totalCount} onPageChange={(e) => onPageChange(e, 2)} className="justify-content-start" />
                    </div>
                </div>
            </div>

        </>
    )
}
export default TenantPaymentListData