import paymentStatusSuccessIcon from '../../../assets/images/svgImages/payment_status_success.svg';
import paymentStatusWarningIcon from '../../../assets/images/svgImages/payment_status_warning.svg';
import paymentStatusDangerIcon from '../../../assets/images/svgImages/payment_status_danger.svg';


interface OverviewDetailCardProps {
  state: string;
  amount: number;
}

const OverviewDetailCard = (props: OverviewDetailCardProps) => {
  let icon = ''
  let severity = ''
  switch (props.state) {
    case 'Paid':
      icon = paymentStatusSuccessIcon
      severity = 'success'
      break
    case 'Unpaid':
      icon = paymentStatusWarningIcon
      severity = 'warning'
      break
    case 'Overdue':
      icon = paymentStatusDangerIcon
      severity = 'danger'
      break
    default:
      icon = paymentStatusSuccessIcon
      severity = 'success'
  }



  const formatFinancial = (number: number) => {
    return number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  };


  return (
    <div className={`payment-status-card ${severity}`} >
      <div className="status-icon"><img src={icon} alt="Payment status icon" /></div>
      <div className="wrapper-info">
        <span className="caption">Current month payment</span>
        <h2>${formatFinancial(props.amount)} ({props.state})</h2>
      </div>

    </div>
  );
}

export default OverviewDetailCard;
