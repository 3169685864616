import overviewIcon from '../assets/images/svgImages/Overview.svg'
import propertyIcon from '../assets/images/svgImages/Properties.svg'
import unitsIcon from '../assets/images/svgImages/Units.svg'
import tenantsIcon from '../assets/images/svgImages/Tenants.svg'
import maintenanceIcon from '../assets/images/svgImages/Maintenance.svg'
import todoIcon from '../assets/images/svgImages/ToDo.svg'
import chatIcon from '../assets/images/svgImages/Chat.svg'
import SettingsIcon from '../assets/images/svgImages/Settings.svg'

import { ROUTES } from './routesConstant'

export const API_URLS = {
  REGISTER: '/auth/registration/',
  LOGIN: '/auth/login/',
  REFRESH_TOKEN: 'auth/token/refresh/',
  EMAIL_VERIFICATION: '/auth/email-verification/',
  UPDATE_EMAIL: '/auth/email-update-verifiction/',
  REQUEST_RESET_PASSWORD_EMAIL: '/auth/request-reset-email/',
  SET_NEW_PASSWORD: '/auth/set-new-password/',
  GET_PROPERTY_TYPES: '/landlord/property-type/',
  GET_PROPERTY_SUBTYPES: '/landlord/property-subtype/',
  PROPERTY: '/landlord/property/',
  PROPERTY_UNIT: 'landlord/property-unit/',
  PROPERTY_DELETE_IMAGE: '/landlord/property-images/{id}/delete/',
  PROPERTY_UNIT_DELETE_IMAGE: '/landlord/property-unit-images/{id}/delete/',
  DISPLAY_TENANTS: '/users/tenants/display_name/',
  ADD_TENANT: '/auth/add-tenant/',
  EDIT_TENANT: '/users/tenants/{id}/tenant_update/',
  TENANTS_LIST: '/users/tenants/',
  DELETE_MULTIPLE_TENANTS: '/users/tenants/delete_tenants/',
  DISPLAY_PROPERTIES: '/landlord/property/display_name/',
  DISPLAY_PROPERTY_UNITS: '/landlord/property-unit/display_name/',
  GET_MAINTENANCE_LIST: '/maintenance/maintenance-task/',
  UPDATE_TASK_AS_COMPLETE: 'maintenance/maintenance-task/{id}/mark_as_in_completed/',
  UPDATE_TASK_AS_INPROGRESS: 'maintenance/maintenance-task/{id}/mark_as_in_progress/',
  REMOVESELECTEDTASK: 'maintenance/maintenance-task/{id}/',
  DELETE_MULTIPLE_TASKS: 'maintenance/maintenance-task/delete_multiple/',
  GET_MAINTENANCE_BY_ID: 'maintenance/maintenance-task/{id}/',
  DELETE_SELECTED_MAINTENANCE_BY_ID: 'maintenance/maintenance-task/{id}/',
  // user
  UPDATE_USER: 'users/me/',
  GET_USER: 'users/me/',
  IS_AUTHENTICATED: 'users/is_authenticated/',
  // location
  GET_STATES: 'landlord/property/get_states_choices/',
  // Subscription
  GET_SUBSCRIPTION_LIST: 'subscriptions/get_subscription/',
  ADD_CREDITCARD: 'payments/payment-methods/add_credit_card/',
  BUY_UNITS: '/subscriptions/buy_unit_connects/',
  GET_DISCOUNTED_COUPON_DETAIL: '/subscriptions/discount-coupan/validate/',
  CANCEL_SUBSCRIPTION: 'subscriptions/{id}/cancel_subscription/',
  ADD_CONNECTED_BANK_ACCOUNTS: 'payments/connected-account/add_connected_account/',
  GET_CONNECTED_BANK_ACCOUNT: 'payments/connected-account/get_connected_account/',
  GET_CONNECTED_BANK_ACCOUNTS: 'payments/connected-account/get_connected_accounts/',
  GET_CUMULATIVE_RENT: 'landlord/property-unit/get_cumulative_rent/',
  GET_SCHEDULE_PAYOUT: 'payments/connected-account/get_schedule_payout/',
  DELETE_SCHEDULE_PAYOUT: 'payments/connected-account/{id}/delete_schedule_payout/',
  EDIT_SCHEDULE_PAYOUT: 'payments/connected-account/{id}/update_schedule_payout/',
  GET_CREDIT_CARD: '/payments/payment-methods/get_credit_card/',
  GET_PAID_CONNECTS: '/subscriptions/get_total_paid_connects/',
  GET_UNIT_PRICE: '/subscriptions/unit-price/get_per_unit_price/',
  GET_PAYMENt_HISTORY_LIST: '/payments/payment-history/',
  UPDATE_PAYMENt_HISTORY_STATUS: '/payments/payment-history/{id}/update_payment_status/',
  UPDATE_PAYMENT_STATUS: '/users/tenants/update_payment_status/',
  SCHEDULE_PAYMENT: 'payments/connected-account/schedule_payout/',
  UPDATE_SCHEDULE_PAYMENT: 'payments/connected-account/update_schedule_payout/',
  INITIATE_PAYOUT: 'payments/connected-account/initiate_payout/',
  UPDATE_ACCOUNT: 'payments/connected-account/{id}/update_connected_account/',
  GET_NON_PAYMENT_OVERVIEW_DATA: 'landlord/dashboard/non_finicial_stats/',
  GET_LANDLORD_METRICS_OVERVIEW_DATA: 'landlord/dashboard/metrics_dashboard/',
  GET_TENANT_METRICS_OVERVIEW_DATA: '/tenant/overview/get_stats/',
  // buy Notifications
  PER_EMAIL_PRICE: 'subscriptions/email/email_pricing/',
  PER_SMS_PRICE: 'subscriptions/sms/sms_pricing/',
  BUY_NOTIFICATIONS: 'subscriptions/notification-credits/buy_notification_credits/',
  GET_EMAILS_CREDITS: 'subscriptions/notification-credits/get_email_credits/',
  GET_SMS_CREDITS: 'subscriptions/notification-credits/get_sms_credits/',
  CALCULATE_PRICE: 'todo/calculate_notification_price/',
  // tenant
  TENANT_LANDING_PAGE: '',
  GET_TENANT_PAYMENT_HISTORY: 'tenant/overview/payment_history/',
  CREATE_MAINTENANCE_TASK: 'maintenance/maintenance-task/',
  GET_NON_FINANCIAL_STATS_FOR_TENANT: 'tenant/overview/get_stats/',
  SETUP_PAYMENT_INTENT_FOR_TENANT: 'payments/tenant/get_setup_intent_secret/',
  REGISTER_TENANT: 'tenant/registration/',
  TENANT_PAY_RENT: 'payments/tenant/pay_rent/',
  TENANT_PAYMENT_SCHEDULING: 'payments/tenant/schedule-payment/',
  TENANT_PAYMENT_METHODS: 'payments/tenant/get_payment_method/',
  TENANT_MAINTENANCE_EDIT: 'maintenance/maintenance-task/{id}/',
  GET_NOTIFICATIONS: 'notifications/notification/',
  MARK_AS_READ_NOTIFICATION: 'notifications/notification/{id}/mark_as_read',
  TREE_SELECT_UNITS_GROUPS: 'landlord/property/get_grouped_units/',

  // todo
  TODO_TASK: '/todo/',
  RESUME_TASK: '/resume/',
  PAUSE_TASK: '/pause/',
  CALCULATE_PRICE_FROM_TASK: 'todo/calculate_notification_price_from_task/',
  NOTIFICATIONS_SENT: 'subscriptions/notification-credits/notifications_sent_current_month/',

  // chat
  CHATS: 'chats/',
  USER_TENANTS_DISPLAY_NAME: '/users/tenants/',
  USER_LADNDLORDS_DISPLAY_NAME: 'users/landlords/',
  GET_USER_BY_ID: 'users/',
  LAST_MESSAGES: 'chats/last_messages/',
  CHAT_HISTORY: 'chats/conversation_history/',
  SCHEDULED_MESSAGES: 'chats/scheduled_messages/',
  MARK_AS_READ_MESSAGE: 'chats/mark_as_read/',
  CANCEL_SCHEDULED_MESSAGE: '/cancel/',

  // Overview
  LANDLORD_OVERVIEW_LIST: 'landlord/dashboard/payment_history/',
}

export const WS_URLS = {
  CHAT: '/chat/',
  MAINTENANCE_NOTIFICATIONS: '/maintenance/notifications/',
  LEASE_END_NOTIFICATIONS: '/lease-end/notifications/',
  TODO_NOTIFICATIONS: '/todo/notifications/',
  CHAT_NOTIFICATIONS: '/chat/unread_chats/',
  ZERO_CREDITS: '/zero_credits/',
}

export const SidebarMenus = [
  { name: 'Overview', icon: overviewIcon, path: ROUTES.OVERVIEW },
  {
    name: 'Properties',
    icon: propertyIcon,

    path: ROUTES.PROPERTIES,
  },
  { name: 'Units', icon: unitsIcon, path: ROUTES.UNITS },
  { name: 'Tenants', icon: tenantsIcon, path: ROUTES.TENANTS },
  // { name: "Payments", icon: transactionsIcon, path: ROUTES.PAYMENTS_OVERVIEW },
  {
    name: 'Maintenance',
    icon: maintenanceIcon,

    path: ROUTES.MAINTENANCE,
  },
  { name: 'To-Do', icon: todoIcon, path: ROUTES.TODO },
  { name: 'Chat', icon: chatIcon, path: ROUTES.CHAT },
  { name: 'Settings', icon: SettingsIcon, path: ROUTES.LANDLORD_SETTINGS },
]

export const TenantSideMenu = [
  {
    name: 'Overview',
    icon: overviewIcon,
    path: ROUTES.TENANT_OVERVIEW,
  },
  // { name: "Payments", icon: shoppingCartIcon, activeIcon: shoppingCartActive, path: ROUTES.TENANT_PAYMENTS },
  {
    name: 'Maintenance',
    icon: maintenanceIcon,
    path: ROUTES.TENANT_MAINTENANCE,
  },
  { name: 'Chat', icon: chatIcon, path: ROUTES.CHAT },
  { name: 'Settings', icon: SettingsIcon, path: ROUTES.TENANT_SETTINGS },
]

export const DaysSelection = [
  1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27,
  28, 29, 30, 31,
]

export const SettingsTabLabels = {
  PERSONAL: 'Personal',
  PAYMENT_METHODS: 'Payment Methods',
  SUBSCRIPTIONS: 'Subscriptions',
  PAYOUT: 'Payout',
}

export const TodoScheduleOptions = [
  { label: 'Every day', value: 'd' },
  { label: 'Every week', value: 'w' },
  { label: 'Every 2 weeks', value: '2w' },
  { label: 'Every month', value: 'm' },
  { label: 'Every year', value: 'y' },
  { label: 'Custom', value: 'custom' },
]

export const NotificationRecipientOptions = [
  { label: 'Me', value: 'm' },
  { label: 'Tenant', value: 't' },
  { label: 'Me and Tenant', value: 'mt' },
]

export const NotificationsTypetOptions = [
  { label: 'SMS', value: 's' },
  { label: 'Email', value: 'e' },
  { label: 'SMS and Email', value: 'b' },
]

export const RepeatTypeOptions = [
  { label: 'Day', value: 'd' },
  { label: 'Week', value: 'w' },
  { label: 'Month', value: 'm' },
  { label: 'Year', value: 'y' },
]

export const MonthlyRecurrenceOptions = [
  { label: 'Monthly on day 13', value: 'm' },
  { label: 'Monthly on second 13', value: 'm' },
]

export const WeekDaysValue = {
  MO: 'Mo',
  TU: 'Tu',
  WE: 'We',
  TH: 'Th',
  FR: 'fr',
  SA: 'Sa',
  SU: 'Su',
}

export const WeekDaysValuesNames = [
  { label: 'M', value: 'Mo' },
  { label: 'Tuesday', value: 'Tu' },
  { label: 'Wednesday', value: 'We' },
  { label: 'Thursday', value: 'Th' },
  { label: 'Friday', value: 'Fr' },
  { label: 'Friday', value: 'Sa' },
  { label: 'Sunday', value: 'Su' },
]

export const WeekDaysNames = [
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
  'Sunday',
]
export const WeekDaysNamesShorts = ['Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa', 'Su']

export const Hours = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]
export const Minutes = [
  0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26,
  27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50,
  51, 52, 53, 54, 55, 56, 57, 58, 59,
]
export const Meridiem = [
  { label: 'AM', value: 'a' },
  { label: 'PM', value: 'p' },
]

export const scheduleMessage = [
  { label: 'now', value: 'n' },
  { label: 'tomorrow', value: 't' },
  { label: 'next week', value: 'w' },
  { label: 'custom', value: 'c' },
]
