import { useEffect, useState, useRef } from 'react'
import moment from 'moment'

import { Badge } from 'primereact/badge'
import { Column } from 'primereact/column'
import { DataTable } from 'primereact/datatable'
import { Paginator, PaginatorPageChangeEvent } from 'primereact/paginator'
import { classNames } from 'primereact/utils'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { overviewService } from '../../helpers/overviewService'
import { ErrorToast, SuccessToast } from '../../helpers/toster'
import { PaymentHistoryListData } from '../../types/overview'
import Dropdown from '../common/Dropdown/Dropdown'

const paginationTemplate = {
  layout: 'PrevPageLink PageLinks NextPageLink',
}

interface PaymentHistoryListProps {
  getPaymentHistoryData: () => void
  totalPaymentHistoryCount: number
  paymentHistoryList: PaymentHistoryListData[]
}

const PaymentHistoryList = (props: PaymentHistoryListProps) => {
  const { paymentHistoryList, totalPaymentHistoryCount, getPaymentHistoryData } = props

  const [first, setFirst] = useState(0)
  const [rows, setRows] = useState(8)
  const [currentPage, setCurrentPage] = useState(1)
  const [rowsToShow, setRowsToShow] = useState<PaymentHistoryListData[]>([])
  const [selectedItemsId, setSelectedItemsId] = useState<number[]>([])

  useEffect(() => {
    setRowsToShow(paymentHistoryList.slice(0, 8))
  }, [paymentHistoryList])

  const onPageChange = (e: PaginatorPageChangeEvent) => {
    setFirst(e.first)
    setRows(e.rows)
    setCurrentPage(e.page + 1)
    setRowsToShow(paymentHistoryList.slice(e.first, e.rows * (e.page + 1)))
  }

  const updateStatus = async (ids: number[], status: string) => {
    const data = {
      ids: ids,
      status: status,
    }
    const response: any = await overviewService.updatePaymentStatus(data)
    if (response?.success) {
      getPaymentHistoryData
      SuccessToast('Payment status updated successfully')

      setSelectedItemsId([])
    } else {
      ErrorToast(response?.msg || 'Error in status update')
    }
  }

  const StatusTemplate = (rowData: PaymentHistoryListData) => {
    return (
      <Badge
        className={classNames({
          'status-badge badge-list': rowData.status === 'p',
          'status-badge badge-success': rowData?.status === 'u',
        })}

        severity={rowData.status === 'p' ? 'success' : rowData.status === 'u' ? 'danger' : null}
        value={rowData.status_display}
      />
    )
  }

  const dateTemplate = (date: string) => {
    return <span>{moment(date).format("MMMM D, YYYY")}</span>
  }


  const rentTemplate = (rowData: PaymentHistoryListData) => {
    return `$${rowData.payment_amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`;
  };

  const dropDownTemplate = (rowData: PaymentHistoryListData) => {
    const DropdownRef = useRef<any>(null)
    const id = [rowData.tenant]
    const DropdownItems = [
      {
        text: `Mark as ${rowData.status === 'p' ? 'Unpaid' : 'Paid'}`,
        onClick: (e: any) => {
          DropdownRef.current?.toggle(e)
          updateStatus(id, rowData.status === 'p' ? 'u' : 'p')
        },
      },
    ]
    return (
      <Dropdown ref={DropdownRef} onClick={(e: any) => DropdownRef.current?.toggle(e)} content={DropdownItems} />
    )
  }

  return (
    <>
      <div className='data-list datatable-listing pb-2 mb-3'>
        <DataTable
          scrollable
          editMode='row'
          value={rowsToShow}
          dataKey='id'
          tableStyle={{ minWidth: '50rem' }}
        >
          <Column className='fw-semibold text-black' field='tenant_name' header='Tenant name'></Column>
          <Column className='fw-semibold text-black' field='payment_amount' header='Payment amount' body={rentTemplate}></Column>
          <Column className='fw-semibold text-black' field='updated' header='Payment date' body={dateTemplate}></Column>
          <Column className='fw-semibold text-black' field='status' header='Status' body={StatusTemplate}></Column>
          <Column className='d-flex justify-content-end' body={dropDownTemplate}></Column>
        </DataTable>

        <div className='pagination-section mt-3 d-flex align-items-center'>
          <div className='total-records'>
            Results: {first + 1}-
            {totalPaymentHistoryCount - first < rows
              ? first + (totalPaymentHistoryCount - first)
              : rows * currentPage}{' '}
            of {totalPaymentHistoryCount}
          </div>
          {paymentHistoryList.length > 8 && (
            <div className='pagination-container ms-auto'>
              <Paginator
                template={paginationTemplate}
                first={first}
                rows={rows}
                totalRecords={totalPaymentHistoryCount}
                onPageChange={(e) => onPageChange(e)}
                className='justify-content-start'
              />
            </div>
          )}
        </div>
      </div>
    </>
  )
}

export default PaymentHistoryList
