import { useEffect, useState } from 'react'
import axios from 'axios'

import { Row, Col, Input, Label } from 'reactstrap'
import { useAppDispatch, useAppSelector } from '../../hooks/storeHook'
import { setActivePageHeader } from '../../store/auth/authSlice'
import { savePaymentHistoryList } from '../../store/overview/overviewSlice'
import { overviewService } from '../../helpers/overviewService'
import { ErrorToast } from '../../helpers/toster'
import { propertiesService } from '../../helpers/propertiesService'
import { LandlordOverviewMetricsData } from '../../types/overview'
import { PropertiesListData } from '../../types/properties'
import PaymentHistoryList from '../../components/PaymentHistoryList'
import { OverviewDetailChartCard } from '../../components/common/OverviewDetailChartCard/OverviewDetailChartCard'
import NoListingView from '../../components/common/NoListingView/NoListingView'
import { CountCard, CardData } from '../../components/common/CountCard/CountCard'

import summaryPropertyIcon from '../../assets/images/svgImages/summary-property-icon.svg'
import summaryTenantIcon from '../../assets/images/svgImages/summary-tenant-icon.svg'
import summaryUnitsIcon from '../../assets/images/svgImages/summary-units-icon.svg'
import summaryTaskIcon from '../../assets/images/svgImages/summary-task-icon.svg'



const Overview = () => {
  const dispatch = useAppDispatch()

  const { paymentHistoryList, totalListCount } = useAppSelector((state) => state.overViewReducer)
  const [properties, setProperties] = useState<PropertiesListData[]>([])
  const [propertySelected, setPropertySelected] = useState(0)
  const [dasboardMetricsData, setDasboardMetrics] = useState({} as LandlordOverviewMetricsData)
  const [cardData, setCardData] = useState<CardData[]>([])
  const [dueAmount, setDueAmount] = useState(
    {
      amount: 0,
      paid_percent: 0.0,
      due_percent: 0.0,
    }
  )

  const dueOptions = {
    legend: "none",
    pieSliceText: "none",
    pieHole: 0.7,
    pieStartAngle: 0,
    tooltip: { trigger: "none" },
    slices: {
      0: { color: "#ACC5C3" },
      1: { color: "white" },
    },
  };

  const paidUnpaidPercentageOptions = {
    legend: "none",
    pieSliceText: "none",
    pieHole: 0.7,
    pieStartAngle: 0,
    tooltip: { trigger: "none" },
    slices: {
      0: { color: "#F2AC66" },
      1: { color: "#398880" },
    },
  };

  const dueData = [
    ["State", "Amount"],
    ["Due", dueAmount.due_percent],
    ["Paid", dueAmount.paid_percent],
  ];



  useEffect(() => {
    dispatch(setActivePageHeader('Overview'));
    getProperties()
    setDueAmount(
      {
        amount: dasboardMetricsData?.total_unpaid_amount || 0,
        paid_percent: dasboardMetricsData?.total_paid_percent || 0,
        due_percent: dasboardMetricsData?.total_unpaid_percent || 0,
      }
    )
  }, [])

  useEffect(() => {
    getCardsData()
    setDueAmount(
      {
        amount: dasboardMetricsData?.total_unpaid_amount || 0,
        paid_percent: dasboardMetricsData?.total_paid_percent || 0,
        due_percent: dasboardMetricsData?.total_unpaid_percent || 0,
      }
    )
  }, [dasboardMetricsData])

  useEffect(() => {
    if (propertySelected === 0) {
      getPaymentHistoryListData()
      getLandlordMetricsOverviewData()

    } else {
      getLandlordMetricsOverviewData(propertySelected)
      getPaymentHistoryListData(propertySelected)
    }
    getCardsData()
  }, [propertySelected])

  const getCardsData = () => {

    if (propertySelected === 0) {
      setCardData([
        {
          title: 'Properties',
          count: dasboardMetricsData?.total_properties,
          icon: summaryPropertyIcon,
        },
        {
          title: 'Tenants',
          count: dasboardMetricsData?.total_tenants,
          icon: summaryTenantIcon,
        },
        {
          title: 'Empty units',
          count: dasboardMetricsData?.total_empty_units,
          icon: summaryUnitsIcon,
        },
        {
          title: 'tasks',
          count: dasboardMetricsData?.total_maintenance_tasks,
          icon: summaryTaskIcon,
        },
      ])
    } else {

      setCardData([
        {
          title: 'Tenants',
          count: dasboardMetricsData?.total_tenants,
          icon: summaryTenantIcon,
        },
        {
          title: 'Empty units',
          count: dasboardMetricsData?.total_empty_units,
          icon: summaryUnitsIcon,
        },
        {
          title: 'tasks',
          count: dasboardMetricsData?.total_maintenance_tasks,
          icon: summaryTaskIcon,
        },
      ])
    }
  }

  const getProperties = async () => {
    try {
      const response = await propertiesService.getPropertyList();
      setProperties(response.results)
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.data?.detail) {
          ErrorToast(err.response?.data?.detail || 'Error in get Properties')
        } else {
          ErrorToast('Error in get Properties')
        }
      }
    }
  }

  const getPaymentHistoryListData = async (propertyId?: number) => {
    try {
      const response = await overviewService.getLandlordPaymentHistoryList(propertyId);
      dispatch(savePaymentHistoryList(response, response.length))
    } catch (err) {
      if (axios.isAxiosError(err)) {
        if (err.response?.data?.detail) {
          ErrorToast(err.response?.data?.detail || 'Error in get Payment History List')
        } else {
          ErrorToast('Error in get Payment History List')
        }
      }
    }
  }

  const getLandlordMetricsOverviewData = async (propertyId?: number) => {
    const response = await overviewService.getLandlordMetricsOverviewData(propertyId)
    setDasboardMetrics(response)

  }

  const formatAmount = (number: number) => {
    return number ? number.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") : '0';
  }

  return (
    <div className='p-4'>
      <Row className="pb-2">
        <Col lg={4}>
          <Label>Current overview</Label>
          <Input id="properties" name="properties" type="select" onChange={(e: any) => { setPropertySelected(parseInt(e.target.value)) }}>

            <option value="0">All Properties</option>
            {properties.map((property) => (
              <option key={property.id} value={property.id}>{property.title}</option>
            ))}
          </Input>
          <p className="caption">You can choose to visualize only one property.</p>
        </Col>
      </Row>
      <Row className='pb-4'>
        <Col lg={6} className='mb-4 p-2 p-0'>
          <OverviewDetailChartCard className='green-card' chartData={dueData}
            chartOptions={dueOptions}>
            <p className="caption m-0">Current month`s total unpaid amount</p>
            <h2>{`$${formatAmount(dasboardMetricsData.total_unpaid_amount)}`}</h2>
          </OverviewDetailChartCard>
        </Col>
        <Col lg={6} className='mb-4 p-lg-2 p-0'>
          <OverviewDetailChartCard className='overview-chart-card' chartData={dueData}
            chartOptions={paidUnpaidPercentageOptions}>
            <Row>
              <Col>
                <p className="caption m-0"><span className='circle-overview-chart' style={{ backgroundColor: "#F2AC66" }}></span>Total percentage of paid</p>
                <h2>{`${dasboardMetricsData.total_paid_percent ? (dasboardMetricsData.total_paid_percent).toFixed(1) : 0}%`}</h2>
              </Col>
            </Row>
            <Row>
              <Col>
                <p className="caption m-0"><span className='circle-overview-chart' style={{ backgroundColor: "#398880" }}></span>Total percentage of due</p>
                <h2>{`${dasboardMetricsData.total_unpaid_percent ? (dasboardMetricsData.total_unpaid_percent).toFixed(1) : 0}%`}</h2>
              </Col>
            </Row>
          </OverviewDetailChartCard>
        </Col>
        <CountCard
          heading='Overview'
          data={cardData}
        />
      </Row>

      {paymentHistoryList.length ? (
        <PaymentHistoryList paymentHistoryList={paymentHistoryList} totalPaymentHistoryCount={totalListCount} getPaymentHistoryData={getPaymentHistoryListData} />
      ) :
        <NoListingView noPropText="You Do Not Have Any Payment History" addPropText="Please Check" />
      }
    </div>
  )
}

export default Overview
