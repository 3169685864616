import { useEffect, useState } from 'react'

import { Col, Row } from 'reactstrap'
import { setActivePageHeader } from '../../store/auth/authSlice'
import { useAppDispatch, useAppSelector } from '../../hooks/storeHook'
import { TenantOverviewMetricsData } from '../../types/overview'
import { saveOverviewPaymentList } from '../../store/tenant/tenantSlice'
import TenantPaymentListData from './TenantPaymentListPage'
import OverviewDetailCard from '../../components/common/OverviewDetailCard/OverviewDetailCard'
import { overviewService } from '../../helpers/overviewService'
import NoListingView from '../../components/common/NoListingView/NoListingView'
import { CountCard, CardData } from '../../components/common/CountCard/CountCard'

import summaryUnitsIcon from '../../assets/images/svgImages/summary-units-icon.svg'
import summaryTaskIcon from '../../assets/images/svgImages/summary-task-icon.svg'

const TenantOverview = () => {
  const dispatch = useAppDispatch()

  const { overviewPaymentList, totalListCount } = useAppSelector((state) => state.tenantReducer)
  const [overviewData, setOverviewData] = useState<TenantOverviewMetricsData>()
  const [cardData, setCardData] = useState<CardData[]>([])

  useEffect(() => {
    dispatch(setActivePageHeader('Overview'))
    getOverviewData();
    getPaymentHistoryData();
  }, [])


  useEffect(() => {
    setCardData([
      {
        icon: summaryUnitsIcon,
        title: 'Total maintenance requests',
        mobile_title: 'Maintenance requests',
        count: overviewData?.open_inprogress_tasks || 0,
      },
      {
        icon: summaryTaskIcon,
        title: 'Total tasks',
        mobile_title: 'Tasks',
        count: overviewData?.total_tasks || 0,
      },
    ])
  }, [overviewData])

  const getOverviewData = async () => {
    try {
      const response = await overviewService.getTenantMetricsOverviewData()
      setOverviewData(response)
    } catch (error) {
      console.log('error', error)
    }
  }

  const getPaymentHistoryData = async () => {
    try {
      const response = await overviewService.getTenantPaymentHistory()
      dispatch(saveOverviewPaymentList(response, response.length))
      console.log(response)
    } catch (error) {
      console.log('error', error)
    }
  }


  return (
    <>
      <div className='p-4'>
        <h1>Overview</h1>
        <Row className='pb-4'>
          <Col lg={4} className='mb-4'>
            <OverviewDetailCard state={overviewData?.payment_status || "Unpaid"} amount={overviewData?.monthly_rent || 0} />
          </Col>
          <Col>
            <CountCard heading='Overview' data={cardData} />
          </Col>
        </Row>
        <h1 className='mb-4'>Payment history</h1>
        {overviewPaymentList?.length ?
          <TenantPaymentListData paymentListData={overviewPaymentList} totalCount={totalListCount} /> :
          <NoListingView noPropText="You Do Not Have Any Payment History" addPropText="Please Check" />
        }
      </div>
    </>
  )
}

export default TenantOverview
